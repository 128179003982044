html, body {
  box-sizing: border-box;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  height: 100%;
  margin: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 0;
}

.container {
  align-items: flex-start;
  color: rgba(30, 30, 30, 1);
  display: flex;
  height: 100%;
  margin: 0;
  min-height: 100vh;
  padding: 1.5rem;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 42rem;
  padding-top: 25vh;
}

.bio {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.heading, .subheading {
  font-weight: 500;
  margin: 0;
}

.heading {
  font-size: 3rem;
  margin-bottom: 0;
}

.subheading {
  font-size: 28px;
}

a {
  text-decoration: none;
  cursor: pointer;
}

/* Option to give text a white background for accessibility */
.text {
  background-color: rgba(255,255,252,.9);
  padding: 5px;
}

.contact-icons {
  text-align: left;
}

.contact-icons a {
  padding: 0;
  margin-right: 10px;
  font-size: 48px;
  background-color: white;
  color: rgba(40, 40, 40, 1);
}

.contact-icons a:hover {
  color: rgba(120, 100, 100, 1);
}

#myCanvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -5;
}

@media (min-width: 768px) {
  .container {
    padding: 3rem;
  }

  .heading {
    font-size: 4.5rem;
  }

  .subheading {
    font-size: 36px;
  }

  .bio {
    font-size: 1.25rem;
  }
}
